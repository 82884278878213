<template>
    <div class="data-provider-table__connectors">
        <template v-for="connector in connectors">
            <img
                :src="connector.img"
                v-b-tooltip.hover="connector.subtype"
                v-if="
                    configuredConnectors.findIndex(
                        el => el.type === connector.type && el.subtype === connector.subtype
                    ) >= 0
                "
            />
        </template>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        connectors: {
            type: Array,
            default: () => [],
        },
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            collect: 'collect/all',
            process: 'process/all',
            report: 'report/all',
        }),
        configuredConnectors() {
            return this.getConnectorsCb(this.item.variables)
        },
    },
    methods: {
        getConnectorsCb(variables) {
            return variables
                .map(variable => {
                    if (variable.value && variable.value.type && variable.value.id) {
                        if (variable.value.type === 'collect') {
                            return this.collect.find(el => el.id === variable.value.id)
                        }
                        if (variable.value.type === 'process') {
                            return this.process.find(el => el.id === variable.value.id)
                        }
                        if (variable.value.type === 'report') {
                            return this.report.find(el => el.id === variable.value.id)
                        }
                    }

                    if (variable.values) {
                        return variable.values
                            .map(value => {
                                if (value.type && value.id) {
                                    if (value.type === 'collect') {
                                        return this.collect.find(el => el.id === value.id)
                                    }
                                    if (value.type === 'process') {
                                        return this.process.find(el => el.id === value.id)
                                    }
                                    if (value.type === 'report') {
                                        return this.report.find(el => el.id === value.id)
                                    }
                                }
                            })
                            .filter(Boolean)
                    }

                    if (variable.variables) {
                        return this.getConnectorsCb(variable.variables)
                    }

                    return null
                })
                .flat()
                .filter(Boolean)
        },
    },
}
</script>

<style></style>
