<template>
    <div class="animated fadeIn">
        <b-table :items="reportList" :fields="fields" show-empty class="report-list-table">
            <template v-slot:cell(success)="row">
                <span class="success-thumb">
                    <b-spinner
                        v-if="row.item.status === 'In Progress'"
                        class="spinner-running"
                        sm
                        label="Spinning"
                        variant="primary"
                    ></b-spinner>
                    <i v-else-if="row.item.success" class="icon fa fa-check success" />
                    <i v-else class="icon fa fa-times failed" />
                </span>
            </template>
            <template v-slot:cell(actions)="row">
                <span>
                    <span v-b-tooltip.hover title="Show Details" v-if="!row.detailsShowing">
                        <i
                            @click="retrieveDetailsAndToggle(row.item, row.index, row.toggleDetails)"
                            class="icon-magnifier-add magnifier-action pointer-action"
                        />
                    </span>
                    <span v-b-tooltip.hover title="Hide Details" v-else>
                        <i
                            @click="retrieveDetailsAndToggle(row.item, row.index, row.toggleDetails)"
                            class="icon-magnifier-remove magnifier-action pointer-action"
                        />
                    </span>
                </span>
            </template>
            <template v-slot:row-details="row">
                <b-table-simple class="report-details-table" small stacked>
                    <b-tbody>
                        <b-tr>
                            <b-td v-for="(value, key) in row.item.details" :stacked-heading="key" :key="key">
                                {{ value }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </template>
        </b-table>
        <b-row align-h="center">
            <span v-if="!singleReport">
                <span v-b-tooltip.hover title="Retrieve more" v-if="!noMoreRecords">
                    <i @click="retrieveMore" class="fa fa-angle-double-down pointer-action retrieve-more-action" />
                </span>
                <span v-b-tooltip.hover title="No more reports" v-else>
                    <i class="fa fa-angle-double-down retrieve-more-action disabled-retrieve-more-action" />
                </span>
            </span>
        </b-row>
    </div>
</template>

<script>
import Loading from '@/components/loading.vue'

export default {
    components: {
        Loading,
    },
    props: {
        filterId: {
            type: String,
            default: () => null,
        },
        filterType: {
            type: String,
            default: () => null,
        },
        singleReport: {
            type: Boolean,
            default: () => false,
        },
    },
    data: () => {
        return {
            reportError: {
                state: true,
                message: null,
            },
            reportList: [],
            fields: ['startedAt', 'finishedAt', 'duration', 'status', 'success', 'actions'],
            reportCursor: null,
            noMoreRecords: false,
        }
    },
    created() {
        this.$store.commit('loading/PROCESSING', `Retrieving the latest report`)
        this.axios
            .get(`${process.env.VUE_APP_NODE_API_HOST}/reports/scenario`, {
                params: {
                    id: this.filterId,
                    filterType: this.filterType,
                    projectId: this.$store.state.project.active.id,
                    latest: true,
                },
            })
            .then((response) => {
                response.data.data.queryData.forEach((element) => {
                    this.reportList.push({
                        id: element.id,
                        startedAt: element.startedAt ? this.parseLocalDate(element.startedAt) : null,
                        finishedAt: element.finishedAt ? this.parseLocalDate(element.finishedAt) : null,
                        duration: this.timeDiff(element.finishedAt, element.startedAt),
                        status: this.parseStatus(element.status),
                        success: element.success,
                    })
                })
                if (!this.reportList.length > 0) this.noMoreRecords = true
                this.reportCursor = response.data.data.newCursorId
                this.$store.commit('loading/PROCESSED')
                this.reportError = {}
            })
            .catch((exception) => {
                this.$store.commit('loading/PROCESSED')
                this.reportError.state = false
                this.reportError.message = exception.response.data.additionalInfo
            })
    },
    methods: {
        timeDiff(finishedAt, startedAt) {
            if (!finishedAt || !startedAt) return ''
            const diff = new Date(finishedAt).getTime() - new Date(startedAt).getTime()
            let diffSeconds = Math.floor(diff / 1000)
            let diffMinutes = 0
            let diffHours = 0
            if (diffSeconds > 59) {
                diffMinutes = Math.floor(diffSeconds / 60)
                diffSeconds = diffSeconds - diffMinutes * 60
                if (diffMinutes > 59) {
                    diffHours = Math.floor(diffMinutes / 60)
                    diffMinutes = diffMinutes - diffHours * 60
                }
            }
            const diffSecondsDisplay = diffSeconds < 10 ? `0${diffSeconds}` : `${diffSeconds}`
            const diffMinutesDisplay = diffMinutes < 10 ? `0${diffMinutes}` : `${diffMinutes}`
            const diffHoursDisplay = diffHours < 10 ? `0${diffHours}` : `${diffHours}`
            return `${diffHoursDisplay}:${diffMinutesDisplay}:${diffSecondsDisplay}`
        },
        parseStatus(status) {
            return status
                .split('_')
                .map((str) => str.charAt(0).toUpperCase() + str.substring(1).toLowerCase())
                .join(' ')
        },
        parseLocalDate(_date) {
            const date = new Date(_date)
            return `${date.toLocaleDateString('pl-PL')} ${date.toLocaleTimeString('pl-PL')}`
        },
        retrieveMore() {
            this.$store.commit('loading/PROCESSING', `Retrieving more reports`)
            this.axios
                .get(`${process.env.VUE_APP_NODE_API_HOST}/reports/scenario`, {
                    params: {
                        id: this.filterId,
                        filterType: this.filterType,
                        projectId: this.$store.state.project.active.id,
                        cursorId: this.reportCursor,
                    },
                })
                .then((response) => {
                    response.data.data.queryData.forEach((element) => {
                        this.reportList.push({
                            id: element.id,
                            startedAt: element.startedAt ? this.parseLocalDate(element.startedAt) : null,
                            finishedAt: element.finishedAt ? this.parseLocalDate(element.finishedAt) : null,
                            duration: this.timeDiff(element.finishedAt, element.startedAt),
                            status: this.parseStatus(element.status),
                            success: element.success,
                        })
                    })
                    if (this.reportCursor === response.data.data.newCursorId) this.noMoreRecords = true
                    else this.reportCursor = response.data.data.newCursorId
                    this.$store.commit('loading/PROCESSED')
                    this.reportError = {}
                })
                .catch((exception) => {
                    this.$store.commit('loading/PROCESSED')
                    this.reportError.state = false
                    this.reportError.message = exception.response.data
                })
        },
        async retrieveDetailsAndToggle(item, index, toggleDetails) {
            if (!item.details) await this.retrieveDetails(item, index)
            toggleDetails()
        },
        async retrieveDetails(item, index) {
            this.$store.commit('loading/PROCESSING', `Retrieving details`)
            await this.axios
                .get(`${process.env.VUE_APP_NODE_API_HOST}/reports/scenario/${item.id}`, {
                    params: {
                        projectId: this.$store.state.project.active.id,
                    },
                })
                .then((response) => {
                    this.reportList[index].details = response.data.data
                    this.$store.commit('loading/PROCESSED')
                    this.reportError = {}
                })
                .catch((exception) => {
                    this.$store.commit('loading/PROCESSED')
                    this.reportError.state = false
                    this.reportError.message = exception.response.data.additionalInfo
                })
        },
    },
}
</script>

<style lang="scss">
.icon {
    font-size: 1.1rem;
}
.success {
    color: green;
}
.failed {
    color: red;
}
.magnifier-action {
    font-weight: 600;
    padding: 0 1.2rem;
    color: #4b4b4c;
}
.retrieve-more-action {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.3rem;
    color: #4b4b4c;
}
.disabled-retrieve-more-action {
    color: #858586;
}
</style>
