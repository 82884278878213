<template>
    <div>
        <b-card class="reports-list">
            <b-card-header class="card-header-main">
                <b-row class="reports-list-header">
                    <b-col>
                        <project-router-link to="/reports/dataproviders/source">
                            <b-button
                                id="add-new-report-button"
                                :disabled="isViewer"
                                variant="primary"
                                class="text-nowrap"
                            >
                                <i class="icon-plus mr-1"></i>Add new Report
                            </b-button>
                        </project-router-link>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <wit-table
                    mode="server"
                    :search="true"
                    :fields="columns"
                    :request-function="requestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    ref="dataProvidersTable"
                    :default-per-page="10"
                >
                    <template v-slot:cell(createdAt)="{item}"> {{ formatDate(item.createdAt) }} </template>
                    <template v-slot:cell(updatedAt)="{item}"> {{ formatDate(item.updatedAt) }} </template>

                    <template v-slot:cell(connectors)="{item}">
                        <configured-connectors :connectors="connectors[item.customQueryRef.id]" :item="item" />
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <DetailsAction :routerLink="`/reports/dataproviders/${item.id}`" />
                            <RemoveAction :removeResource="showRemoveModal" :resource="item" />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>

        <RemoveRecordModal
            v-model="showModal"
            :removeElement="removeDataProvider"
            :recordId="removeRecordId"
            @close="removeRecordId = null"
        >
            <div slot="error">
                <Feedback :state="dataProviderError.state" :invalid="dataProviderError.message" />
            </div>
        </RemoveRecordModal>

        <AutomaticWorkflowModal v-model="showWorkflow" :custom-schedule="ongoingSchedule" :historical="historical" />

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import moment from 'moment'
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import DetailsAction from '@/components/Actions/DetailsAction.vue'
import RemoveAction from '@/components/Actions/RemoveAction.vue'
import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import RemoveRecordModal from '@/components/Modals/RemoveRecordModal.vue'
import ReportList from '@/components/ReportList.vue'
import WitTable from '@/components/WitTable.vue'

import ConfiguredConnectors from './ConfiguredConnectors.vue'

import {collectTypes} from '@/shared/collectTypes'
import {processTypes} from '@/shared/processTypes'
// import {fetchAll} from '@/shared/fetchAll'

export default {
    components: {
        ConfiguredConnectors,
        DetailsAction,
        Loading,
        ReportList,
        WitTable,
        RemoveAction,
        RemoveRecordModal,
        Feedback,
        AutomaticWorkflowModal: () => import('@/components/Modals/AutomaticWorkflowModal.vue'),
    },
    mounted() {
        this.reloadTableInterval = setInterval(this.reloadTable, 5000)
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        isViewer() {
            return this.activeProject.role == 'Viewer'
        },
        types() {
            return [...collectTypes, ...processTypes].reduce(
                (acc, curr) => ({
                    ...acc,
                    [`${curr.type} ${curr.subtype}`]: {...curr, img: curr.img ? require(`@/assets/${curr.img}`) : null},
                }),
                {}
            )
        },
        connectors() {
            return this.dataProviderCategories.reduce((acc, curr) => ({...acc, [curr.id]: curr.connectors}), {})
        },
    },
    destroyed() {
        clearInterval(this.reloadTableInterval)
    },
    notifications: {
        createError: {
            type: VueNotifications.types.error,
            timeout: 8000,
            title: 'Could not prepare the form',
        },
    },
    data() {
        return {
            reportError: {
                state: true,
                message: '',
            },
            searchUrl: `${process.env.VUE_APP_SEARCH_HOST}/search/report`,
            apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/reports/dataProviders`,
            columns: ['name', 'type', 'subtype', 'createdAt', 'updatedAt', 'actions'],
            dataProviderCategories: [],
            showModal: false,
            showWorkflow: false,
            removeRecordId: null,
            dataProviderError: {
                state: null,
                message: null,
            },
            ongoingSchedule: null,
            historical: true,
        }
    },
    async created() {
        const {dataProviderCreated, ongoingSchedule, historical, createError} = this.$route.query

        if (dataProviderCreated) {
            this.$router.replace({query: {}})
            this.ongoingSchedule = ongoingSchedule ? decodeURIComponent(ongoingSchedule) : null
            this.historical = historical ? historical === 'true' : true
            this.showWorkflow = true
        }

        if (createError) {
            this.$router.replace({query: {}})
            this.createError()
        }

        // this.$store.commit('loading/PROCESSING', `Preparing the table...`)
        // await Promise.all([this.fetchDataProviderCategories(), fetchAll(this.$store, this.activeProject.id)])
        // this.$store.commit('loading/PROCESSED')
    },
    methods: {
        async requestFunction(requestParams) {
            const {query, limit: length, page} = requestParams
            const offset = (page - 1) * length
            const filter = `subtype:'Predefined Reports'`
            const projectId = this.$store.state.project.active.id

            const apiUrl = query ? this.searchUrl : this.apiUrl
            const params = query ? {query, length, offset, filter} : {projectId, ...requestParams}

            try {
                const response = await this.axios.get(apiUrl, {params})

                return {
                    data: response.data.data,
                    count: response.data.metadata.count,
                }
            } catch (error) {
                this.dispatch('error', error)
            }
        },
        reloadTable() {
            if (!document.hidden && this.$refs.dataProvidersTable)
                this.$refs.dataProvidersTable.fetchData({silent: true})
        },
        removeDataProvider() {
            this.dataProviderError.state = null
            this.dataProviderError.message = null

            this.$store.commit('loading/PROCESSING', `Removing Query Job...`)
            this.axios
                .delete(`${process.env.VUE_APP_NODE_API_HOST}/reports/dataProviders/${this.removeRecordId}`)
                .then(response => {
                    this.reloadTable()

                    this.showModal = false
                    this.$store.commit('loading/PROCESSED')
                })
                .catch(exception => {
                    if (exception.response.status === 409) {
                        const resourcesToDelete = JSON.parse(exception.response.data.data)
                            .map(({name}) => name)
                            .join(', ')
                        this.dataProviderError.message = `We cannot delete this resource. We have detected that it occurs on other resources. First, remove it from these places: ${resourcesToDelete}`
                    } else {
                        this.dataProviderError.message = exception.response.data.data
                    }
                    this.dataProviderError.state = false
                    this.$store.commit('loading/PROCESSED')
                })
        },
        showRemoveModal(dataProvider) {
            this.removeRecordId = dataProvider.id
            this.dataProviderError.state = null
            this.dataProviderError.message = null
            this.showModal = true
        },
        async fetchDataProviderCategories() {
            try {
                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/customQuery/project/${this.$store.state.project.active.id}`
                )

                this.dataProviderCategories = response.data.data.map(dataProvider => {
                    const connectors = []

                    if (dataProvider.preview) {
                        const _connectors = [
                            ...dataProvider.preview.requiredConnectors,
                            ...dataProvider.preview.optionalConnectors,
                        ]

                        connectors.push(
                            ..._connectors.map(connector => {
                                const {type, subtype, img} = this.types[connector.type]

                                return {
                                    img,
                                    type,
                                    subtype,
                                }
                            })
                        )
                    }

                    return {...dataProvider, connectors}
                })
            } catch (exception) {
                this.dataStudioError.state = false
                this.dataStudioError.message = exception.response.data.additionalInfo
            }
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
    },
}
</script>

<style lang="scss">
.data-provider-table__connectors {
    img {
        height: 18px;
        margin-right: 5px;
    }
}
</style>
