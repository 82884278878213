<template>
    <span v-b-tooltip.hover :title="customTitle ? customTitle : 'Details'" @click="() => $emit('click')"
        ><project-router-link
            v-if="routerLink"
            class="details-resource"
            :disabled="disabled"
            v-bind:class="{'pointer-disabled': disabled}"
            :to="routerLink"
        >
            <i class="fa icon-settings pointer-action" v-bind:class="{'fa-spin': spin}"> </i
        ></project-router-link>
        <span v-else class="details-resource" v-bind:class="{'pointer-disabled': disabled}"
            ><i class="fa icon-settings pointer-action" v-bind:class="{'fa-spin': spin}"> </i
        ></span>
    </span>
</template>

<script>
export default {
    props: {
        routerLink: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        customTitle: {
            type: String,
        },
        spin: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style>
.details-resource i {
    font-weight: 600;
}
.details-resource:hover {
    text-decoration: none;
    color: #4b4b4c;
}
</style>
